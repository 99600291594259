import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  Button,
  Box,

} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CustomGoogleLogInButton from "./CustomGoogleLoginButton";
import { GoogleOAuthProvider } from '@react-oauth/google';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const Closebutton = require("./button_close.svg").default
const Centerdatailogo = require("./centerdataiiconmob.svg").default
const userprofile = require("./uprofileicon.svg").default
const googleicon = require("./googleicon.svg").default

import { styled } from '@mui/material/styles';
import { toast } from "react-toastify";
interface NavbarProps {
  handlelogin: () => void;
  handlesignup: () => void;
  handleGoogleLogin: (token: string) => void;
  isActive: boolean
  firstName?: string;
  profileImage?: string,
  navigateTo: (path: string) => void

}

const Navbar: React.FC<NavbarProps> = ({ isActive, firstName, profileImage, handlelogin, handlesignup, handleGoogleLogin, navigateTo }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const handlesignupdrawer = () => {

    handlesignup()
    handleDrawerClose()
  }

  const handlelogindrawer = () => {

    handlelogin()
    handleDrawerClose()
  }

  const handlePageType = () => {
    toast.info("Page is under development and will be released shortly!")
  }

  return (
    <Box>
      <AppBar position="static" sx={{ boxShadow: 'none', backgroundColor: isActive ? 'transparent' : '#ffffff' }}>
        <Toolbar >
          <IconButton
            edge="start"
            color="success"
            aria-label="menu"
            onClick={handleDrawerOpen}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <MenuIcon style={{ color: isActive ? "#ffffff":'black' }} />
          </IconButton>

          <Box sx={{
            display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
          }} justifyContent={'start'} gap={3}>


            <StyledMenuTypo onClick={handlePageType} isActive={isActive} >About DatAI</StyledMenuTypo>

            <Box>
              <StyledMenuTypo data-test-id='handleServices' onClick={() => navigateTo("Services")} isActive={isActive}>Services</StyledMenuTypo>
            </Box>
            <Box>
              <StyledMenuTypo isActive={isActive} onClick={handlePageType} >Contact Us</StyledMenuTypo>
            </Box>
          </Box>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexGrow={1} margin={2}>
            <Box>
              <StyledCenterLogoImage onClick={() => { navigateTo("Home") }} src={Centerdatailogo} alt="datailogo" />
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex", lg: "flex" },
              justifyContent: "end",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {firstName ? <>
              <StyledMenuTypo isActive={isActive} >
                {`Hi ${firstName}`}
              </StyledMenuTypo>
              <Box onClick={()=>navigateTo("CustomisableUserProfileEdit")}
                    sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <img src={profileImage ? profileImage : userprofile} alt="user-profile_icon" height={'50px'} width={'50px'} style={{borderRadius:"50%"}} />
                <KeyboardArrowDownIcon sx={{ color: isActive ? "#ffffff" : '#0F172A' }} />
              </Box>
            </>
              : <>
                <StyledMenuTypo onClick={handlelogin} isActive={isActive}
                >
                  Login
                </StyledMenuTypo>
                <StyledMenuTypo isActive={isActive} onClick={handlesignup}
                >
                  Sign Up
                </StyledMenuTypo>
              </>}
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="top"
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true,
        }}
        PaperProps={{
          sx: {
            width: "100%",
            height: "auto",
          },
        }}
      >

        <Box padding={'24px'} >
          <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
            <Box>

              <img src={Closebutton} alt="close_icon" onClick={handleDrawerClose} />
            </Box>
            <Box>

              <img src={Centerdatailogo} alt="datailogo" />
            </Box>
            <Box>

              <img src={userprofile} alt="user-profile_icon" />
            </Box>

          </Box>
          <Box marginTop={2} display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={2}>

            <Box>
              <Button onClick={handlesignupdrawer}
                data-test-id="goToHomeButton"
                data-testid="askToJarvysButton"
                style={{
                  color: 'black',
                  borderRadius: '8px',
                  fontFamily: 'Open Sans',
                  padding: '10px 16px',
                  cursor: 'pointer',
                  width: '100%',
                  backgroundColor: '#fffff',
                  textTransform: 'none',
                  border: '1px solid #B3B3B3',
                }}>Sign Up</Button>
            </Box>

            <GoogleOAuthProvider clientId='421316066014-5d53ulirpigeiqq2bddkrslrhua74rfh.apps.googleusercontent.com'>
              <CustomGoogleLogInButton name="Register" data-testid="googleSignUpButton" onSuccess={(token) => { handleGoogleLogin(token) }} onError={() => { }} />
            </GoogleOAuthProvider>
            <Box>
              <Button onClick={handlelogindrawer}
                data-test-id="goToHomeButton"
                data-testid="askToJarvysButton"
                style={{
                  color: '#fffff',
                  borderRadius: '8px',
                  fontFamily: 'Open Sans',
                  padding: '10px 16px',
                  cursor: 'pointer',
                  width: '100%',
                  backgroundColor: 'black',
                  textTransform: 'none'
                }}  >Login</Button>
            </Box>

          </Box>

        </Box>
      </Drawer>
    </Box>
  );
};

export default Navbar;
const StyledMenuTypo = styled(Typography)<{ isActive: boolean }>(({ isActive }) => ({

  fontFamily: 'Open Sans',
  margin: "2px",
  cursor: 'pointer',
  fontWeight: 400,
  fontSize: '18px',
  color: isActive ? '#fffff' : '#000000',

}));



const StyledCenterLogoImage = styled('img')(({ theme }) => ({
  display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '12vw', width: '140px',
  '@media (max-width: 1034px)': {
    cursor: 'pointer', marginRight: '12vw', width: '140px',
  }, '@media (max-width: 1018px)': {
    cursor: 'pointer', width: '140px',
  },
  '@media (max-width: 974px)': {
    cursor: 'pointer', width: '140px',
  }, '@media (max-width: 918px)': {
    cursor: 'pointer', width: '140px',
  },
  '@media (max-width: 858px)': {
    cursor: 'pointer', width: '140px',
  }, '@media (max-width: 768px)': {
    width: '140px',
    cursor: 'pointer',
  }, '@media (max-width: 626px)': {
    width: '100px',
    cursor: 'pointer',
  }, '@media (max-width: 468px)': {
    cursor: 'pointer',
    width: '100px',
  }, '@media (max-width: 386px)': {
    width: '100px',
    cursor: 'pointer',
  },
}));
