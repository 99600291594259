import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import InteractivefaqsSharedController from "./InteractivefaqsSharedController";
import { faqIcon, rightArrow } from "./assets";
// Customizable Area End


export default class Interactivefaqs extends InteractivefaqsSharedController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box style={webStyles.mainContainer}>
                <Typography style={webStyles.helpCentreMainTitle}>Help Centre</Typography>
                <Box style={webStyles.breadCrumbBox} >
                    <Typography data-test-id='goToDashboard' style={webStyles.dashboardBread} onClick={this.goToDashboard}>Dashboard</Typography>
                    <img style={webStyles.rightArrowStyle} src={rightArrow} alt="right arrow" />
                    <Typography style={webStyles.helpCentreBread}>Help Centre</Typography>
                </Box>
                <FreqAskTitle>Frequently asked questions</FreqAskTitle>
                <Box style={webStyles.centerBox}>
                    <img style={webStyles.faqIconStyle} src={faqIcon} alt="faq icon" />
                    <Box>
                        <Typography style={webStyles.contactUsTxt}>Contact Us</Typography>
                        <Typography style={webStyles.fillFormTxt}>Fill in the form</Typography>
                    </Box>
                </Box>

                <Box>
                    {
                        this.state.faq && this.state.faq.map((faqs, indexs) => {
                            return <Accordion key={faqs.id}>

                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`panel${indexs + 1}-content`}
                                    id={`panel${indexs + 1}-header`}
                                >
                                    <Typography>{faqs.attributes.question}</Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    {faqs.attributes.answer}
                                </AccordionDetails>

                            </Accordion>
                        })
                    }
                </Box>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const FreqAskTitle = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '48px',
    fontWeight: 600,
    color: '#000000',
    lineHeight: '65.37px',
    marginTop: '32px',
    '@media (max-width: 720px)': {
        fontSize: '36px',
        lineHeight: '40.37px',
    },
    '@media (max-width: 540px)': {
        fontSize: '28px',
        lineHeight: '32.37px',
    },
    '@media (max-height: 420px)': {
        fontSize: '20px',
        lineHeight: '22.37px',
    },
    '@media (max-height: 380px)': {
        fontSize: '16px',
        lineHeight: '18.37px',
    },
    '@media (max-height: 320px)': {
        fontSize: '14px',
        lineHeight: '16.37px',
    },
})

const webStyles = {
    mainContainer: {
        padding: '0 24px 24px 36px'
    },
    helpCentreMainTitle: {
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 600,
        color: '#000000',
        marginTop: '53px',
    },
    breadCrumbBox: {
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '17px',
    },
    dashboardBread: {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#909090',
        cursor: 'pointer',
    },
    rightArrowStyle: {
        margin: '0 13px 0 13px',
    },
    helpCentreBread: {
        fontFamily: 'Open Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#000000',
    },
    centerBox: {
        marginTop: '24px',
        backgroundColor: '#F3F3F3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        padding: '16px 0 16px 32px',
        marginBottom: '24px',
    },
    faqIconStyle: {
        marginRight: '25px',
    },
    contactUsTxt: {
        fontFamily: 'Roboto',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '21.09px',
    },
    fillFormTxt: {
        fontFamily: 'Open Sans',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '19.07px',
        color: '#848484',
        marginTop: '4px',
    },
}
// Customizable Area End