import React from 'react';
import { TextField, Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { pickersYearClasses, pickersMonthClasses } from "@mui/x-date-pickers";
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { toast } from 'react-toastify';
import { TextFieldProps } from '@mui/material/TextField';
import { StyledDateContainer } from "../../../../components/src/StyledComponents"

interface Props {
    onChange: (date: Date) => void
        selectedDate: Date | null,
            meridiem: string,
}

const DatePickerDropDown: React.FC<Props> = ({ onChange, selectedDate, meridiem }) => {
    const timezoneOffsetInMinutes = new Date(). getTimezoneOffset();
    const minDate = new Date(new Date().getTime()+timezoneOffsetInMinutes*60000)
    const maxDate = new Date(new Date().getTime()+timezoneOffsetInMinutes*60000 + 60 * 24 * 60 * 60 * 1000)
    const handleTimeChange = (value: Date | null) => {
        if (value) {
            if (!isNaN(value.getTime())) {
                onChange(value)
            }
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledDateContainer>
                <StyledGridContainer >
                    <Grid item >
                        <Typography sx={Styles.dropdownLabel}> Day</Typography>
                        <MobileDatePicker
                            data-testid='datePicker'
                            value={selectedDate}
                            disablePast
                            timezone="UTC"
                            maxDate={maxDate}
                            onChange={(newValue) => {
                                if (newValue) {
                                    onChange(newValue);
                                }
                            }}
                            views={['day']}
                            openTo={'day'}
                            slots={{
                                textField: (textFieldProps: TextFieldProps) => <StyledDateTextField
                                    disabled
                                    value={selectedDate ? selectedDate.getDate() : selectedDate}
                                    {...textFieldProps} sx={{ maxWidth: "116px" }}
                                    InputProps={{ endAdornment: <KeyboardArrowDownIcon /> }}
                                />
                            }}
                            slotProps={{
                                day: {
                                    sx: {
                                        fontFamily: "Open Sans",
                                        fontSize: "15px",
                                        '&.Mui-selected': {
                                            backgroundColor: 'black !important',
                                        },
                                        '&.Mui-selected:hover': {
                                            backgroundColor: 'black',
                                        },
                                        '&[aria-selected="true"]': {
                                            backgroundColor: 'black',
                                        }
                                    }
                                },
                                actionBar: {
                                    actions: ['cancel', 'accept'],
                                    sx: {
                                        '& .MuiButton-root': {
                                            color: 'black',
                                            fontWeight: 600,
                                        },
                                    },
                                }
                            }}
                        />
                    </Grid>
                    <Grid item >
                        <Typography sx={Styles.dropdownLabel}> Month</Typography>
                        <MobileDatePicker
                            data-testid='monthPicker'
                            value={selectedDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            timezone='UTC'
                            onChange={(newValue) => {
                                if (newValue) {
                                    onChange(newValue);
                                }
                            }}
                            views={['month']}
                            openTo={'month'}
                            slots={{
                                textField: (textFieldProps: TextFieldProps) => {
                                    return <StyledDateTextField {...textFieldProps}
                                        placeholder='MM'
                                        sx={{ maxWidth: "116px" }}
                                        value={selectedDate ? selectedDate.getMonth() + 1 : selectedDate}
                                        InputProps={{ endAdornment: <KeyboardArrowDownIcon /> }}
                                    />
                                }
                            }}
                            slotProps={{
                                layout: {
                                    sx: {
                                        [`& .${pickersMonthClasses.monthButton}.${pickersMonthClasses.selected}`]:
                                        {
                                            backgroundColor: "black",
                                            "&:focus, &:hover": {
                                                backgroundColor: "black",
                                            },
                                        },
                                    },
                                },
                                actionBar: {
                                    actions: ['cancel', 'accept'],
                                    sx: {
                                        '& .MuiButton-root': {
                                            color: 'black',
                                            fontWeight: 600,
                                        },
                                    },
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography sx={Styles.dropdownLabel}> Year</Typography>
                        <MobileDatePicker
                            data-testid='yearPicker'
                            value={selectedDate}
                            timezone='UTC'
                            minDate={minDate}
                            maxDate={maxDate}
                            onChange={(newValue) => {
                                if (newValue) {
                                    onChange(newValue);
                                }
                            }}
                            views={['year']}
                            openTo={'year'}
                            slots={{
                                textField: (textFieldProps: TextFieldProps) => <StyledDateTextField
                                    {...textFieldProps}
                                    sx={{ maxWidth: "116px" }}
                                    InputProps={{ endAdornment: <KeyboardArrowDownIcon /> }}
                                />
                            }}
                            slotProps={{
                                layout: {
                                    sx: {
                                        [`& .${pickersYearClasses.yearButton}.${pickersYearClasses.selected}`]: {
                                            backgroundColor: "black",
                                            "&:focus, &:hover": {
                                                backgroundColor: "black",
                                            },
                                        },
                                    },
                                },
                                actionBar: {
                                    actions: ['cancel', 'accept'],
                                    sx: {
                                        '& .MuiButton-root': {
                                            color: 'black',
                                            fontWeight: 600,
                                        },
                                    },
                                }
                            }}
                        />
                    </Grid>
                </StyledGridContainer>
                <Box>
                    <Typography sx={{
                        fontFamily: "Open Sans",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#4D4D4D",
                        marginBottom: "4px"
                    }}> Time <span style={{ color: "#B3B3B3" }}>(UTC)</span></Typography>
                    <Box sx={{ display: "flex", gap: "12px" }}>
                        <TimeField
                            data-testid='timePicker'
                            format="hh:mm"
                            value={selectedDate}
                            onChange={handleTimeChange}
                            onBlur={() => { if (selectedDate) { onChange(new Date(selectedDate)) } }}
                            sx={{
                                width: "114px",
                                height: "48px",
                                '& .MuiInputBase-root': {
                                    height: "100%",
                                    borderRadius: "8px",
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#DDDDDDDD",
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#000000",
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: "#000000",
                                },
                            }}
                        />
                        <Box sx={{ ...Styles.amPm, background: meridiem === "AM" ? "#FFFDD8" : "#FFFFFF" }}
                            data-testid='AMPicker'
                            onClick={() => {
                                if (selectedDate) {
                                    const modifiedDate = new Date(selectedDate)
                                    let hours = selectedDate.getHours() % 12
                                    modifiedDate.setHours(hours)
                                    onChange(modifiedDate)
                                } else {
                                    toast.error("Please select a time!")
                                }
                            }}
                        >AM</Box>
                        <Box sx={{ ...Styles.amPm, background: meridiem === "PM" ? "#FFFDD8" : "#FFFFFF" }}
                            data-testid='PMPicker'
                            onClick={() => {
                                if (selectedDate) {
                                    let hours = selectedDate.getHours() % 12
                                    hours += 12
                                    selectedDate.setHours(hours)
                                    onChange(selectedDate)
                                } else {
                                    toast.error("Please select a time!")
                                }
                            }}
                        >PM</Box>
                    </Box>
                </Box>
            </StyledDateContainer>
        </LocalizationProvider>
    );
}

export default DatePickerDropDown;


const StyledDateTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        height: "48px",
        borderRadius: '8px',
        '& .MuiInputBase-input': {
            fontFamily: "Open Sans",
            fontWeight: 400,
            fontSize: '14px',
            color: "#4D4D4D",
        },
        '& fieldset': {
            borderColor: '',
            border: "1px solid #DDDDDDDD"
        },
        '&.Mui-focused fieldset': {
            border: "1px solid black",
        },
        '& .MuiInputBase-input.Mui-disabled': {
            background: "transparent"
        },
        '&.Mui-disabled': {
            backgroundColor: '#EFEFEF',
        },
    },
})

const StyledGridContainer = styled(Box)({
    display: 'flex',
    gap: '12px',
    '@media (max-width: 371px)': {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
    },
})



const Styles = {
    dropdownLabel: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: 400,
        color: "#4D4D4D",
        marginBottom: "4px"
    },
    amPm: {
        height: "48px",
        width: "73px",
        border: "1px solid #DDDDDD",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Open Sans",
    }
}
