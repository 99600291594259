import React from 'react';

import {

  Typography,
  Box,
  Grid,

} from "@mui/material";

const comtwitter = require("./commontwitterlogo.svg").default
const cominsta = require("./commoninsta.svg").default
const comface = require("./commonfacebook.svg").default
const comtitok = require("./commontiktok.svg").default
const comaitool = require("./commmonaitoolbar.svg").default

import { styled } from '@mui/material/styles';
interface footerProps {
  isActive: boolean
}

const Footerbar: React.FC<footerProps> = ({ isActive }) => {


  return (
    <Box>
      <StyleFooter isActive={isActive} >
        <Grid item xs={4}  style={{ flexDirection: 'column' }}>
          <Typography style={{ fontFamily: 'Open Sans', fontSize: '18px', fontWeight: 600, color: isActive?"#ffffff":'#000000' }} >
            DatAI &#169; 2020-2024
          </Typography>
          <Box>
            <ContentBox>
              <Typography style={{  fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', color: isActive?"#ffffff":'#000000' }}>
                Privacy policy
              </Typography>
              <Typography style={{ fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', color: isActive?"#ffffff":'#000000'}}>
                Terms & Conditions
              </Typography>
            </ContentBox>
          </Box>
        </Grid>

        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '200px' }}  >
          <img src={comtwitter} alt="twitterogo" style={{ alignItems: 'center', filter: isActive?'invert(1) brightness(100)':"none"}} />
          <img src={comface} alt="faceboologo" style={{ alignItems: 'center',filter: isActive?'invert(1) brightness(100)':"none" }} />
          <img src={cominsta} alt="instagramlogo" style={{ alignItems: 'center', filter: isActive?'invert(1) brightness(100)':"none" }} />
          <img src={comtitok} alt="tiktoklogo" style={{ alignItems: 'center', filter: isActive?'invert(1) brightness(100)':"none" }} />


        </Box>
        <ToolImgBox sx={{borderColor:isActive?"#ffffff":"#3D3D3D", background:isActive?"#ffffff":"transparent"}}>
          <Typography className='tool_img' sx={{color:'#4D4D4D'}}>{'AI Powered Marketing Tools'}</Typography>
        </ToolImgBox>
      </StyleFooter>
    </Box>
  );
};

export default Footerbar;

const ToolImgBox = styled(Grid)({
   padding: "10px",
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   borderWidth: "0.5px",
   borderStyle:"solid",
   borderRadius: "8px",
   "& .tool_img":{
    fontWeight: 400,
    fontFamily: "Open Sans",
    fontSize: "18px",
    '@media (max-width: 475px)': {
      fontSize: "13px",
    } 
   },
  '@media (max-width: 800px)': {
    width : "100%",
  } 
})

const ContentBox = styled(Grid)({
   display: "flex",
   gap: "16px",
   color: "#4D4D4D"
})

const StyleFooter = styled(Grid)<{ isActive: boolean }>(({ isActive }) => ({
  display: 'flex',
  alignItems: "center",
  justifyContent: 'space-between',
  position: "static",
  zIndex: 1,
  bottom: 0,
  width: '100%',
  backgroundColor: isActive ? 'transparent' : '#F0EEEE',
  padding: '18px 15px',
  '@media (max-width: 918px)': {
    alignItems: "center",
    padding: '18px 0px',
    justifyContent: 'space-around',
    position: 'fixed',
    zIndex: 1,
    bottom: 0
  },
  '@media (max-width: 800px)': {
    alignItems: "start",
    gap: '16px',
    justifyContent: 'flex',
    position: 'static',
    zIndex: 1,
    bottom: 0,
    flexDirection: 'column',
    padding: '24px'
  },
  '@media (max-width: 768px)': {
    alignItems: "start",
    justifyContent: 'center', gap: '16px',
    flexDirection: 'column',
    padding: '24px',
    position: 'static',
    zIndex: 1,
    bottom: 0
  },
}));

