import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start

import { View, StyleSheet } from "react-native";

import { searchIcon, starIcon, seoQuickTips, seoBestPractices, learnSeoBasic, seoStrategies, editPenIcon, backArrowIcon, speakerIcon, regenerateIcon, dislikeIcon, sendIcon } from "./assets";
import {
  styled,
  Box,
  TextField,
  Typography as MuiTypography, Button as MuiButton, IconButton
} from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { ChatHistory } from "./CfmarkyaiapiintegrationController";
import Loader from '../../../components/src/Loader.web';

// Customizable Area End

import CfmarkyaiapiintegrationController, {
  Props,
  configJSON,
} from "./CfmarkyaiapiintegrationController";

export default class Cfmarkyaiapiintegration extends CfmarkyaiapiintegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderGenerateContent = () => {
    const buttons = [
      { name: configJSON.seoQuickTips, icon: seoQuickTips },
      { name: configJSON.seoBestPractices, icon: seoBestPractices },
      { name: configJSON.learnSeoBasics, icon: learnSeoBasic },
      { name: configJSON.seoStrategies, icon: seoStrategies }];
    return (
      <>
        <MainContainer data-testId="seoRecommendations">
          <GenerateBox>
            <HeadingContent>{configJSON.seoRecommendations}</HeadingContent>
            <ExampleContent>{configJSON.exampleContent}</ExampleContent>
            <InputFieldBox>
              <img src={searchIcon} className="search_img" />
              <TextField data-test-id="txtInput" placeholder={configJSON.submitYourURL} value={this.state.contentText} onChange={this.handleGenerateContent} className='textField' />
              <Box data-testid="check-seo-button" onClick={this.handleCheckSeo} className='textFieldBtn SEOBtn'>
                {configJSON.checkSeo}
                <img src={starIcon} className="star_img" />
              </Box>
            </InputFieldBox>
            <SEOBtnBox>
            <Box data-testid="check-seo-button" onClick={this.handleCheckSeo} className='textFieldSEOBtn'>
                {configJSON.checkSeo}
                <img src={starIcon} className="star_img" />
              </Box>
            </SEOBtnBox>
            <ContentHeading>{configJSON.usefulContent}</ContentHeading>
            <StartPointContainer>
              {buttons.map((data, index) => {
                return (
                  <StartPointBox key={index} style={this.state.selectedBtn === index + 1 ? {backgroundColor:'#000'} : {}} onClick={() => { this.handleSelectedBtn(data.name, index + 1) }} data-testid={`seo-button-${index}`}>
                    <img src={data.icon} className="start_img" data-testid={`start-img-${index}`} style={this.state.selectedBtn === index + 1 ? {filter:'invert(100%)'} : {filter:'none'}} />
                    <MuiTypography className="start_point_content" style={this.state.selectedBtn === index + 1 ? {color:'#fff'} : {color:'#4D4D4D'}}>{data.name}</MuiTypography>
                  </StartPointBox>
                );
              })}
            </StartPointContainer>
          </GenerateBox>
        </MainContainer>
      </>
    )
  }

  renderChatSideBar = () => {
    const sortedChatHistory = Object.entries(this.state.chatHistorySorted).sort(([dateA], [dateB]) => 
        new Date(dateB).getTime() - new Date(dateA).getTime()
    );

    return (
        <>
            <ChatSideBarWrapper open={this.state.openChatHistory}>
                <TopButtonSidebar>
                    <MuiButton
                        data-testId="newChatBtn"
                        onClick={() => this.handleNewChatButtonClicked()}
                        sx={webStyle.newChatButton}
                        endIcon={<AddIcon sx={{ fontSize: "16px" }} />}
                    >
                        New chat
                    </MuiButton>
                    <ClearIcon
                        className="crossIcon"
                        onClick={() => this.setState({ openChatHistory: false })}
                        data-testid="close-sidebar-btn"
                    />
                </TopButtonSidebar>
                <PrevChatWrapper>
                    {sortedChatHistory.map(([date, chats]: [string, ChatHistory[]], index) => {
                        const sortedChats = chats.sort((a, b) => 
                            new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime()
                        );

                        return (
                            <DateWiseChatWrapper key={index}>
                                <MuiTypography className="date">
                                    {this.formatDate(date) === 'Today' ? 'Today' : this.formatDate(date)}
                                </MuiTypography>
                                {this.formatDate(date) === 'Today' && (
                                    <MuiButton
                                        data-testId="latest-chat-tab"
                                        sx={this.state.selectedChatFromSidebar === 0 ? webStyle.currChatButton : webStyle.prevChatButton}
                                        onClick={() => this.getOldChats(this.state.currChatId)}
                                        endIcon={<MoreVertIcon sx={{ fontSize: '16px' }} />}
                                    >
                                        <MuiTypography sx={webStyle.name}>{this.state.chatName}</MuiTypography>
                                    </MuiButton>
                                )}
                                {sortedChats.map((chat, y) => (
                                    <MuiButton
                                        data-testId={`history-${index}-${y}`}
                                        sx={this.state.selectedChatFromSidebar === parseInt(chat.id) ? webStyle.currChatButton : webStyle.prevChatButton}
                                        onClick={() => this.getOldChats(chat.id)}
                                        endIcon={<MoreVertIcon sx={{ fontSize: '16px' }} />}
                                        key={chat.id}
                                    >
                                        <MuiTypography sx={webStyle.name}>{chat.attributes.name}</MuiTypography>
                                    </MuiButton>
                                ))}
                            </DateWiseChatWrapper>
                        );
                    })}
                </PrevChatWrapper>
            </ChatSideBarWrapper>
        </>
    );
}


  renderChatScreen = () => {
    const { chatBubblesList } = this.state;
    return (
      <Box display="flex" data-testid='chats-screen'>
        <Loader loading={this.state.isLoading}/>
        {this.renderChatSideBar()}
        <ChatWrapper>
          <ChatHeader>
            <img src={backArrowIcon} alt=""  onClick={() => {this.handleBackToSeoPage()}} data-testId="back-arrow-btn"/>
            <MuiTypography className="headerTxt">{this.state.chatName}</MuiTypography>
            <img src={editPenIcon} alt="" />
            <ChatSidebarMenuIcon>
              <MenuIcon onClick={() => {this.setState({openChatHistory: !this.state.openChatHistory})}} data-testid="menu-icon-btn"/>
            </ChatSidebarMenuIcon>
          </ChatHeader>
          <ChatBubblesWrapper>
            {chatBubblesList
            .sort((a, b) => new Date(a.attributes.created_at).getTime() - new Date(b.attributes.created_at).getTime())
            .map((chat, index) => {
              return <Box key={index}>
                <ChatRightBubble>
                  <MuiTypography className="yourQuestion">Your question</MuiTypography>
                  <MuiTypography className="rightBubble">{chat.attributes.url}</MuiTypography>
                </ChatRightBubble>
                <ChatLeftBubble>
                  <MuiTypography className="name">JARVYS's answer</MuiTypography>
                  <MuiTypography>{chat.attributes.url_and_starting_point ? chat.attributes.url_and_starting_point : ""}</MuiTypography> 
                  
                  <MuiTypography dangerouslySetInnerHTML={{ __html: chat.attributes.recommendation }} />
                </ChatLeftBubble>
              </Box>
            })}
          </ChatBubblesWrapper>
          <StyledInputField
            fullWidth
            id="outlined-adornment-password"
            type={'text'}
            placeholder='Enter your text'
            variant='outlined'
            data-testId="chat-screen-input"
            value={this.state.chatContinueTxt}
            onChange={(e: { target: { value: string } }) => {this.handleChatInputChange(e.target.value)}}
            InputProps={{
              endAdornment: <IconButton
                aria-label="toggle password visibility"
                data-test-id='btnSendMsg'
                edge="end"
                onClick={() => this.handleChatScreenInputButton()}
              >
                <img src={sendIcon} alt="" />
              </IconButton>
            }}
          />
        </ChatWrapper>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
          {!this.state.isChatPage ? this.renderGenerateContent() : this.renderChatScreen()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainContainer = styled(Box)({
  backgroundColor: "#FFFDD8",
  padding: "100px",
  height:'100vh',
  "@media (max-width: 1000px)": {
    padding: "50px 20px 20vh"
  },
  "@media (max-width: 700px)": {
    padding: "50px 30px 40vh" 
  },
  "@media (max-width: 430px)": {
    padding: "50px 20px 70vh" 
  },
})

const GenerateBox = styled(Box)({
  width: "100%"
})

const HeadingContent = styled(Box)({
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "32px",
  color: "#4D4D4D",
  '@media (max-width: 646px)': {
    fontSize:'28px',
  },
  '@media (max-width: 450px)': {
    fontSize:'24px',
  },
  '@media (max-width: 320px)': {
    fontSize:'20px',
  }
})

const ExampleContent = styled(Box)({
  padding: "",
  marginTop: "48px",
  fontFamily: "Open Sans",
  fontWeight: 400,
  fontSize: "18px",
  color:'#4D4D4D',
  lineHeight:'24.51px',
  '@media (max-width: 646px)': {
    fontSize:'16px',
  },
  '@media (max-width: 450px)': {
    fontSize:'14px',
  },
  '@media (max-width: 320px)': {
    fontSize:'12px',
  }
})

const InputFieldBox = styled(Box)({
  width: "100%",
  height: "65px",
  marginTop: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px",
  fontSize: "18px",
  border: "1px solid #DDDDDDDD",
  backgroundColor: "#FFFFFF",
  overflow: "hidden",
  cursor: "pointer",
  "@media (max-width: 700px)": {
    borderRadius: "100px",
  },
  "& .textField": {
    fontSize: "18px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "none !important",
    "& .css-igs3ac": {
      border: "none !important",
    },
    "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
      borderStyle: "unset"
    }
  },
  "& .search_img": {
    height: "20px",
    marginLeft: "15px"
  },
  "& .textFieldBtn": {
    backgroundColor: "#000000",
    width: "200px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    justifyContent: "center",
    gap: "10px",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",
    cursor: "pointer"
  },
  "& .star_img": {
    height: "20px"
  },
  '@media (max-width: 450px)': {
    "& .textFieldBtn": {
      fontSize:'14px',
    }
  },
  "& .SEOBtn":{
    "@media (max-width: 700px)": {
      display: "none",
    },
  }
})

const SEOBtnBox = styled(Box)({
  width: "100%",
  marginTop: "12px",
  height: "65px",
  alignItems: "center",
  display: "none",
  justifyContent: "space-between",
  borderRadius: "8px",
  border: "1px solid #DDDDDDDD",
  backgroundColor: "#FFFFFF",
  overflow: "hidden",
  "@media (max-width: 700px)": {
    display: "flex",
    borderRadius: "100px",
  },
  "& .textFieldSEOBtn": {
    backgroundColor: "#000000",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    color:"#FFFFFF",
    justifyContent: "center",
    gap: "10px",
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: "18px",  
    cursor : "pointer"
  },
 })

const ContentHeading = styled(Box)({
  marginTop: "80px",
  fontFamily: "Open Sans",
  fontWeight: 600,
  fontSize: "18px",
  color: "#4D4D4D",
  marginBottom: "24px"
})

const StartPointContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "20px"
})

const StartPointBox = styled(Box)({
  flex: "1 0 150px",
  border: "0.5px solid #000000",
  borderRadius: "20px",
  padding: "16px",
  textAlign: "center",
  cursor: "pointer",
  "& .start_img": {
    height: "32px",
    color: "#3D3D3D",
  },
  "& .start_point_content": {
    marginTop: "15px",
    fontFamily: "Open Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#4D4D4D",
  },
  "&:hover": {
    backgroundColor: "#000",
  },
  "&:hover .start_img": {
    height: "32px",
    filter: "invert(100%) !important"
  },
  "&:hover .start_point_content": {
    color: "#FFFFFF !important",
  },
});

const ChatSideBarWrapper = styled(Box)(({ open }: { open: boolean }) => ({
  overflowY:'scroll',
  display: 'flex',
  flexDirection:'column',
  width: '275px',
  height:'86vh',
  padding:'16px',
  backgroundColor:'#F7F7F7',
  '@media (max-width: 1150px)':{
    display: open ? 'block' : 'none',
    position:'absolute',
    height:'100vh',
    top:0,
    zIndex:100
  }
}));

const TopButtonSidebar = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems: "center",
  ".crossIcon":{
  },
  '@media (min-width: 1150px)':{
    ".crossIcon":{
      display:'none'
    },
  }
})

const ChatSidebarMenuIcon = styled(Box)({
  marginLeft:'auto',
  display:'none',
  '@media (max-width: 1150px)':{
    display: 'block',
  }
})

const PrevChatWrapper = styled(Box)({
  marginTop:'32px',
  display:'flex',
  flexDirection:'column',
  gap:'24px',
})

const DateWiseChatWrapper = styled(Box)({
  display:'flex',
  flexDirection:'column',
  gap:'4px',
  '.date':{
    marginBottom:'16px',
    fontSize:'16px',
    fontWeight: 400,
    fontFamilty:'Open sans',
    color: '#B3B3B3'
  }
})

const ChatWrapper = styled(Box)({
  display:'flex',
  flexDirection:'column',
  padding:'32px 32px 0 32px',
  width:'100%',
  height:'85vh',
  '@media (max-width: 1150px)':{
    display: 'block',
    padding:'12px 12px 0 12px'
  }
})

const ChatHeader = styled(Box)({
  display:'flex',
  gap:'16px',
  marginBottom:'80px',
  ".headerTxt":{
    fontSize:'18px',
    fontWeight:400
  }

})

const ChatBubblesWrapper = styled(Box)({
  display:'flex',
  flexDirection:'column',
  overflowY:'scroll',
  gap:'16px',
  flexGrow:1,
})

const ChatRightBubble = styled(Box)({
  maxWidth:'520px',
  paddingRight:'8px',
  marginLeft:'auto',
  ".rightBubble": {
    marginLeft:'auto',
    backgroundColor:'#EFEFEF',
    borderRadius:'16px 0 16px 16px',
    position:'relative',
    padding:'8px 16px',
  },
  ".yourQuestion":{
    color:'#4D4D4D',
    fontSize:'16px',
    fontWeight:600,
    fontFamily:'Open sans',
    display:'flex',
    justifyContent:'end',
    marginBottom:'8px'
  },
  "@media (max-width: 800px)": {
    maxWidth:'420px'
  },
})

const ChatLeftBubble = styled(Box)({
  padding:'8px 16px',
  ".name":{
    fontSize:'16px',
    fontWeight:600,
    color:'#4D4D4D',
    fontFamily:'Open sans',
    marginBottom:'8px'
  }
})

const ChatBubbleIcons = styled(Box)({
  marginTop:'8px',
  display:'flex',
  gap:'14px'
})

const StyledInputField = styled(TextField)({
  marginBottom: "0px",
  marginTop: '12px',
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#B3B3B3",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B3B3B3",
      borderWidth: "1px",
    },
  },
  "& .MuiInputBase-root": {
    height: '66px',
    margin: 'auto',
    borderRadius: '100px',
    backgroundColor: "#FFFFFF",
    fontSize: '16px',
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "100px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-input": {
    color: '#000',
    paddingLeft: '20px',
    paddingTop: "17px",
    "&::placeholder": {
      fontSize: "16px",
      color: '#000',
    },
  },
  "& .MuiInputBase-input[type='date']": {
    color: 'red',
    textTransform: 'uppercase',
    lineHeight: "19.2px",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "18px",
    fontFamily: "Lato",
    color: "#DC2626",
  }, 
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B3B3B3",
  },
  '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    border: "1px solid #FFFFFF",
  },
  "& .MuiFormHelperText-contained": {
    marginRight: "0px",
    marginLeft: "0px",
  },
});

const webStyle ={
  newChatButton:{
    flex:1,
    textTransform: 'none',
    color: "#FFFFFF",
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    background:'#000000',
    borderRadius:"8px",
    "&:hover":{
        background:'#000000',
    },
    "@media (max-width: 1150px)": {
      flex: '0 0 auto'
    },
  },
  currChatButton: {
    textTransform: 'none',
    color: "#404040",
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    borderRadius:"8px",
    border:'2px solid #4D4D4D',
    display:'flex',
    justifyContent:'space-between',
    whiteSpace:'nowrap',
    overflow:'hidden'
  },
  prevChatButton: {
    textTransform: 'none',
    color: "#404040",
    fontSize:"14px",
    fontWeight:600,
    fontFamily:"Open Sans",
    padding:'10px',
    borderRadius:"8px",
    display:'flex',
    backgroundColor:'#fff',
    justifyContent:'space-between',
    whiteSpace:'nowrap',
    overflow:'hidden'
  },
  name: {
    width:'100%',
    textOverflow:'ellipsis',
    overflow:'hidden'
  }
}
// Customizable Area End
