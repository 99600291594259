// Customizable Area Start
import React from "react";
import {
Box,
Typography,

} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { userEnagement, tailormade, servicesMain, realTime, contentCreation } from "./assets";
import styled from '@emotion/styled';
const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ServicesController, {
Props, configJSON
} from "./ServicesController";

interface ServicesTypes {
  id: string,
  type: string,
  attributes: {
      title: string,
      description: string,
      orientation: string,
      created_at: string,
      updated_at: string,
      image: string
  }
}

export default class Services extends ServicesController {
  constructor(props: Props) {
    super(props);

  }

render() {
  const { services } = this.state;
  return (
      <ThemeProvider theme={themes}>
             <CustomDividerBox  >
              <img style={webStyles.serviceTopImg} src={servicesMain} alt="" />
             </CustomDividerBox>
             <ServicesListMain>
              <Typography className="servicesTxt">{configJSON.servicesText}</Typography>

              {
                services && services.map((service: ServicesTypes) => {
                  return <>
                  {
                    service.attributes.orientation === 'Portrait' 
                    ? <>
                      <Box className="serviceBox">
                        <Box className="serviceBoxLeft">
                          <Typography className="serviceTitle">{service.attributes.title}</Typography>
                          <Typography className="serviceDesc">{service.attributes.description}</Typography>
                        </Box>
                        <img style={webStyles.serviceImg} className="serviceImg" src={service.attributes.image} alt="" />
                      </Box>
                      <CustomDivider />
                    </>
                  :  service.attributes.orientation === 'Landscape' && <>
                      <Box>
                        <Box className="serviceBoxLeft">
                          <Typography className="serviceTitle">{service.attributes.title}</Typography>
                          <Typography className="serviceDescOpen">{service.attributes.description}</Typography>
                        </Box>
                        <img style={webStyles.serviceImgOpen} src={service.attributes.image} alt="" />
                      </Box>
                      <CustomDivider />
                  </>
                  }
                  </>
                })
              }

            <Box className="secondServiceBoxGrid">
              {
                services && services.map((service: ServicesTypes) => {
                  
                  return <>
                    {
                      service.attributes.orientation === 'Card' && <>
                        
                        {
                          service.attributes.image ? <Box className="secondServiceBoxRight">  
                            <img style={webStyles.secondServiceImg} src={service.attributes.image} alt="" />
                          </Box>
                          : <Box className="secondServiceBoxLeft">
                          <Typography className="serviceTitle">{service.attributes.title}</Typography>
                          <Typography className="secondServiceDesc">{service.attributes.description}</Typography>
                        </Box>
                        }
                        
                      </>
                    }
                  </>
                })
              }
          </Box>    
             </ServicesListMain>
      </ThemeProvider>
    );
  }
}
const webStyles = {
 
  serviceTopImg: {
    width: '100%',
    height: 'auto',
  },


  secondServiceImg: {
    width: '100%',
    height: '100%',
  },
  serviceImg: {
    borderRadius: '8px',
  },
  serviceImgOpen: {
    marginTop: '24px',
    width: '100%',
    borderRadius: '8px',
    height: '400px',
  },
};

const CustomDivider = styled(Typography)({
  width: '100%',
  border: '1px solid #B3B3B3',
  margin: '48px 0 48px 0',
  '@media (max-width: 768px)': {
    margin: '36px 0 36px 0',
  },
})
const CustomDividerBox = styled(Box)({
  width: '100%',
  boxSizing: 'border-box',
  padding: '0 32px',
  '@media (max-width: 768px)': {
    padding: '0 16px',
  },
  '@media (max-width: 480px)': {
    padding: '0 8px',
  },
})

const ServicesListMain = styled(Box)({
  margin: '0 200px 32px 200px',
  '@media (max-width: 1200px)': {
    margin: '0 100px 32px 100px',
  },
  '@media (max-width: 768px)': {
    margin: '0 32px 32px 32px',
  },
  '@media (max-width: 480px)': {
    margin: '0 16px 32px 16px',
  },
  "& .servicesTxt": {
    fontFamily: 'Open Sans',
    fontSize: '36px',
    fontWeight: 600,
    color: '#364AC6',
    margin: '48px 0',
    '@media (max-width: 768px)': {
      fontSize: '28px',
      margin: '36px 0',
    },
    '@media (max-width: 480px)': {
      fontSize: '24px',
      margin: '24px 0',
    },
  },
  "& .serviceBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      flexDirection: 'column',
    },
  },
  "& .serviceBoxLeft": {
    flex: 1,
    '@media (max-width: 768px)': {
      textAlign: 'center',
    },
  },

  "& .serviceTitle": {
    fontFamily: 'Open Sans',
    fontSize: '24px',
    fontWeight: 600,
    color: '#4D4D4D',
    '@media (max-width: 768px)': {
      fontSize: '20px',
    },
    '@media (max-width: 480px)': {
      fontSize: '18px',
    },
  },
  "& .serviceDesc": {
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 400,
    color: '#4D4D4D',
    marginTop: '36px',
    width: '50%',
    '@media (max-width: 1200px)': {
      width: '60%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      marginTop: '16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '14px',
    },
  },
  "& .serviceDescOpen": {
    width: '80%',
    marginTop: '36px',
    fontWeight: 400,
    color: '#4D4D4D',
    fontSize: '18px',
    fontFamily: 'Open Sans',
    '@media (max-width: 1200px)': {
      width: '60%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      marginTop: '16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '14px',
    },
  },
  "& .serviceImg": {
    width: '400px',
    height: 'auto',
    '@media (max-width: 1200px)': {
      width: '350px',
    },
    '@media (max-width: 768px)': {
      width: '100%',
      maxWidth: '300px',
      margin: '0 auto',
      marginTop: '16px',
    },
  },
  '& .secondServiceBoxGrid': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '24px',
  },
  "& .secondServiceBox": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '48px',
    columnGap: '48px',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',
      marginTop: '16px',
    },
  },
  "& .secondServiceBoxLeft": {
    backgroundColor: '#EFEFEF',
    width: '100%',
    padding: '36.5px 40px 36.5px 40px',
    border: '1px solid #DDDDDDDD',
    borderRadius: '8px',
    height: '318px',
    boxSizing: 'border-box' as 'border-box',
    '@media (max-width: 1200px)': {
      marginBottom: '24px',
      width: '70%',
      padding: '24 24 24 24',
    },
    '@media (max-width: 768px)': {
      marginBottom: '16px',
      width: '100%',
    },
  },
  "& .secondServiceBoxRight": {
    width: '100%',
    padding: '24px 24px 24px 24px',
    border: '1px solid #DDDDDDDD',
    borderRadius: '8px',
    backgroundColor: '#DDDDDDDD',
    height: '318px',
    boxSizing: 'border-box' as 'border-box',
    '@media (max-width: 1200px)': {
      width: '70%',
    },
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  "& .secondServiceDesc": {
    marginTop: '36px',
    fontSize: '18px',
    fontWeight: 400,
    color: '#4D4D4D',
    fontFamily: 'Open Sans',
    '@media (max-width: 1200px)': {
      marginTop: '20px',
    },
    '@media (max-width: 768px)': {
      marginTop: '16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '14px',
    },
  },
})
// Customizable Area End