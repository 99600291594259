import React from 'react';
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogContent,
  IconButton,
  Modal,
  Paper
} from '@mui/material';
import { imageIcon, videoIcon, deletesIcon, renameIcon, playIcon, cloudUploadIcon, successIcon } from './assets';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import PhotosLibraryController, { Props, configJSON } from './PhotosLibraryController.web';
import Loader from '../../../components/src/Loader.web';
import Dropzone from 'react-dropzone';
// Customizable Area End

export default class PhotosLibrary extends PhotosLibraryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMyLibraryHeader = () => {
    const { sortContent, searchContent } = this.state;
    return (
      <LibraryHeaderBox>
        <PhotoLibraryHeading>
          <Typography className="pageHeading">{configJSON.myLibrary}</Typography>
          <Button onClick={this.openUploadModal} data-testId="addContentTestID" className="newCampaignButton" endIcon={<AddIcon className='heading_icon'/>}>
            {configJSON.addContent}
          </Button>
        </PhotoLibraryHeading>
        <PhotoLibrarySearchContainer>
          <SearchTextField
            placeholder="Search"
            fullWidth
            data-testId="searchLibraryTestID"
            onChange={this.handleLibrarySearch}
            value={searchContent}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <PhotosLibraryFormControl>
            <Typography className='sort_heading'>{configJSON.shortBy}</Typography>
            <StyledSelect>
            <Select
             fullWidth 
             IconComponent= {KeyboardArrowDownTwoToneIcon}
             data-testId="sortLibraryTestID"
             onChange={this.handleSortData}
             placeholder='Select'
             value={sortContent}
             className='library_sort_select'
             displayEmpty
             renderValue={(selected: string) => {
                     if (selected === "") {
                         return <Box className="place_library_text">{"Select"}</Box>;
                     }
                     return configJSON.sortKey[selected];
                 }}
             MenuProps={{
                     PaperProps: {
                         sx: {
                             backgroundColor: "#F0F0F0 !important",
                             "& .Mui-selected": {
                                 backgroundColor:"#D9D9D9 !important",
                             }
                         },
                     },
                 }}
             >
              <MenuItem className='sort_Item' value="name_asc" >{configJSON.nameAZ}</MenuItem>
              <MenuItem className='sort_Item' value="name_desc">{configJSON.nameZA}</MenuItem>
              <MenuItem className='sort_Item' value="type_image">{configJSON.typeImage}</MenuItem>
              <MenuItem className='sort_Item' value="type_video">{configJSON.typeVideo}</MenuItem>
              <MenuItem className='sort_Item' value="created_at_asc">{configJSON.dateLH}</MenuItem>
              <MenuItem className='sort_Item' value="created_at_desc">{configJSON.dateHL}</MenuItem>
              </Select>
            </StyledSelect>
          </PhotosLibraryFormControl>
        </PhotoLibrarySearchContainer>
      {this.renderContentUploadModal()}
      </LibraryHeaderBox>)
  }

  renderContentUploadModal = () => {
    const { openUploadModal, selectedFile, showValidFile } = this.state;
    return (
      <ModalContainer
        open={openUploadModal}
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        className="deleteModal"
      >
        <Paper className="paper_upload">
          <IconButton
            onClick={this.closeUploadModal}
            className="closeIconBtn"
            data-testId="closeUploadTestID"
          >
            <CloseIcon />
          </IconButton>
          <UploadHeadBox>
            <BoldHeadTypo data-testId="closeLabelTestID">{configJSON.chooseFiles}</BoldHeadTypo>
            <MediumHeadTypo>{configJSON.choiceText}</MediumHeadTypo>
          </UploadHeadBox>
          <Dropzone
            data-testId="selectFileTestID"
            accept={{
              'image/jpeg': ['.jpeg', '.jpg'],
              'image/png': ['.png'],
              'video/mp4': ['.mp4'],
            }}
            multiple={true}
            onDrop={this.handleUploadChange}
          >
          {({ getRootProps, getInputProps }) => (
              <UploadContentBox {...getRootProps()}>
              <input {...getInputProps()} /> 
              <img src={cloudUploadIcon} />
              <UploadContent>
                <BoldTypo>{configJSON.dragDropText}</BoldTypo>
                <MediumTypo>{configJSON.formatsText}</MediumTypo>
              </UploadContent>
              {showValidFile && <ValidBox>{configJSON.fileValidation}</ValidBox>}
                {selectedFile && (
                  <SelectBox>
                    {selectedFile.map((file, index) => (
                      <span className='select_file' key={index}>{file.name}</span>
                    ))}
                  </SelectBox>
                )}
              <UploadBrowseBtn>{configJSON.browseBtn}</UploadBrowseBtn>
            </UploadContentBox>
           )}
          </Dropzone>
         <UploadButtonBox>
          <Button className="cancleBtn" onClick={this.closeUploadModal}>{configJSON.cancelDeleteText}</Button>
          <Button className="savedBtn" disabled = {selectedFile === null ? true : false} onClick={this.handleAddLibrary}>{configJSON.saveText}</Button>
        </UploadButtonBox>
        </Paper>
      </ModalContainer>
    )
  }

  renderPhotoLibrary = () =>{
    const { photoData, openImageMenuIndex } = this.state;
    return(
      <CardBox>
      <Box className="headBox">
        <img src={imageIcon} alt="" />
        <Typography data-testId="openImageLabelTestID">{configJSON.imagesText}</Typography>
      </Box>
      <Box className="cardsBox" >
        {photoData.map((item, index) => (
          <Box className="imageCard" key={index} 
          data-testId="openImageTestID"
          onClick={() => this.openImageModal(item)}
          >
            <Box className="imageBox">
              <img src={item.url} alt="" className="library_img" />
            </Box>
            <Box className="titleBox">
              <Typography title={item.filename} className='file_name'>{item.filename}</Typography>
              <MoreVertIcon
                className={openImageMenuIndex === index ? "moreButtonActive" :"moreButton"}
                data-testId="moreImageTestID"
                onClick={(event) => {
                  event.stopPropagation();
                  this.handleMenu(index);
                }}
              />
              {openImageMenuIndex === index && (
                   <Box className="moreMenu" >
                    <Button className="deleteBtn"  data-testId="deleteBtnTestID"
                      onClick={(event) => { event.stopPropagation();
                                             this.openDeleteModal(item) }} >
                      <img src={deletesIcon} alt=" "/>
                      <Typography data-testId="deleteBtnTextTestID">{ configJSON.deleteText }</Typography>
                    </Button>
                  <Button className="renameBtn" data-testId="renameBtnTestID"
                    onClick={(event) => { event.stopPropagation();
                                   this.openRenameModal(item) }} >
                       <img src={renameIcon} alt=" "/>
                      <Typography data-testId="renameBtnTextTestID">{ configJSON.renameText }</Typography>
                      </Button>
                    </Box>  )}
                 </Box>
                </Box>
                 ))}
             {photoData.length === 0 && this.renderRecordNotFound()}
            </Box>
           </CardBox>
           )
           }

  renderVideoLibrary = () =>{
    const { videoData, openVideoMenuIndex } = this.state;
    return(
      <CardBox>
          <Box className="headBox">
            <img src={videoIcon} alt="" />
            <Typography>{configJSON.videosText}</Typography>
          </Box>
          <Box className="cardsBox">
            {videoData.map((item, index) => (
              <Box className="imageCard" key={index} 
              data-testId="openImageTestID"
              onClick={() => this.openImageModal(item)}
              >
                <Box className="videoBox">
                  <video src={item.url} className="library_video"/>
                  <img src={playIcon} className="playBtn" />
                </Box>
                <Box className="titleBox">
                  <Typography title={item.filename} className='file_name' data-testId="moreVideoLabelTestID">{item.filename}</Typography>
                  <MoreVertIcon
                    className={openVideoMenuIndex === index ? "moreButtonActive" :"moreButton"}
                    data-testId="moreVideoTestID"
                    onClick={(event) => {
                      event.stopPropagation()
                      this.handleVideoMenu(index);
                    }}
                  />
                  {openVideoMenuIndex === index && (
                    <Box className="moreMenu">
                      <Button className="deleteBtn"
                       data-testId="deleteBtnTestID"
                       onClick={(event) => {
                        event.stopPropagation();
                        this.openDeleteModal(item);
                      }}
                      >
                        <img src={deletesIcon} alt="" />
                        <Typography data-testId="deleteBtnTextTestID">{configJSON.deleteText}</Typography>
                      </Button>

                      <Button className="renameBtn"
                       data-testId="renameBtnTestID"
                       onClick={(event) => {
                        event.stopPropagation();
                        this.openRenameModal(item);
                      }}
                      >
                        <img src={renameIcon} alt="" />
                        <Typography data-testId="renameBtnTextTestID">{configJSON.renameText}</Typography>
                      </Button>
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
           {videoData.length === 0 && this.renderRecordNotFound()}
          </Box>
        </CardBox>
    )
  }

  renderViewModal = () =>{
    const { selectedUrl , contentType } = this.state;
    const urlType = contentType.includes("image");
    return(
      <StyledDialog
      open={!!selectedUrl}
      onClose={this.closeImageModal}
    >
      <DialogContentStyled>
        <Box display="flex" justifyContent="space-between">
          <IconButton
            onClick={this.closeImageModal}
            data-testId="closeImageTestID"
            className="closeIconBtn"
          >
            <CloseIcon data-testId="closeImagelabelTestID"/>
          </IconButton>
        </Box>
        {urlType ? 
        <img
          src={selectedUrl || ""}
          alt="Selected"
          className='image_content'
        />
        : 
        <video src={selectedUrl || ""}
        controls
        autoPlay
        className='video_content'
        />
      }
      </DialogContentStyled>
    </StyledDialog>
    )
  }

  renderDeleteModal = () =>{
    const { openDeleteModal, deleteFileName} = this.state;
    return(
      <ModalContainer
      open={openDeleteModal}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className="deleteModal"
    >
      <Paper className="paper">
        <IconButton
          onClick={this.closeDeleteModal}
          data-testId="closeDeleteTestID"
          className="closeIconBtn"
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="body1" data-testId="closeDeleteLabelTestID">
          {configJSON.deleteImageText}
        </Typography>
        <Typography variant="body2">{configJSON.sureDeleteText + deleteFileName + "?"}</Typography>
        <Box className="buttonBox">
          <Button className="cancleBtn" onClick={this.closeDeleteModal}>
            {configJSON.cancelDeleteText}
          </Button>
          <Button className="deleteBtn" data-testId="deleteLibrayTestID" onClick={this.handleDeleteLibraryData}>{configJSON.yesDeleteText}</Button>
        </Box>
      </Paper>
    </ModalContainer>
    )
  }

  renderRenameModal = () =>{
    const { renameText } = this.state;
    return(
      <ModalContainer
          open={this.state.openRenameModal}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          className="deleteModal"
        >
          <Paper className={this.state.openRenameModal?"renamepaper paper":"paper"}>
            <IconButton
              onClick={this.closeRenameModal}
              data-testId="closeRenameTestID"
              className="closeIconBtn"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="body1" data-testId="closeRenameLabelTestID">
              {configJSON.renameText}
            </Typography>
            <RenameTextField
            value={renameText}
            onChange={(event)=>{this.handleNameChange(event)}}
            data-testId="renameTestID"
            />
            <Box className="buttonBox">
              <Button className="cancleBtn" onClick={this.closeRenameModal}>
                {configJSON.cancelDeleteText}
              </Button>
              <Button className="deleteBtn" onClick={this.handleRenameLibraryData} data-testId="saveLibrayTestID" disabled={renameText === "" ? true : false} >{configJSON.saveText}</Button>
            </Box>
          </Paper>
        </ModalContainer>
    )
  }

  renderSuccessModal = () =>{
    const { openSuccessModal } = this.state;
     return (
      <ModalContainer
      open={openSuccessModal}
      onClose={this.closeSuccessModal}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
    >
       <Paper className="paper_upload paper_success">
        <IconButton
          onClick={this.closeSuccessModal}
          data-testId="closeSuccessTestID"
          className="closeIconBtn"
        >
          <CloseIcon />
        </IconButton>
        <UploadHeadBox>
            <BoldHeadTypo data-testId="closeSuccessLabelTestID">{configJSON.successUpload}</BoldHeadTypo>
            <img src={successIcon} className="succes_image" />
        </UploadHeadBox>
      </Paper>
    </ModalContainer>
     )
  }

  renderRecordNotFound = () =>{
    return (
      <RecordNotFoundBox>
        <Typography className='record_content'>{configJSON.recordNotFound}</Typography>
      </RecordNotFoundBox>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading } = this.state;
    return (
        <MainContainer>
        <Loader loading={isLoading}/>
          {this.renderMyLibraryHeader()}
          {this.renderPhotoLibrary()}
          {this.renderVideoLibrary()}
          {this.renderViewModal()}
          {this.renderDeleteModal()}
          {this.renderRenameModal()}
          {this.renderSuccessModal()}
        </MainContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  padding: "50px 24px",
  '@media (max-width: 600px)': {
    padding: '15px',
  },
})

const LibraryHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

const PhotoLibraryHeading = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  "& .heading_icon": {
    fontSize: "16px"
  },
  "& .pageHeading": {
    fontSize: "32px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  },

  "& .newCampaignButton": {
    textTransform: 'none',
    color: "#FFFFFF",
    ontSize: "16px",
    fontWeight: 600,
    fontFamily: "Open Sans",
    padding: '10px 40px',
    background: '#000000',
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      background: '#000000',
    },
    '@media (max-width: 600px)': {
      padding: '10px 30px',
    },
    '@media (max-width: 400px)': {
      width: "100%"
    },
  },

  '@media (max-width: 646px)': {
    alignItems: "start",
    justifyContent: "space-between",
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    alignItems: "center",
    justifyContent: "space-between",
  },
  '@media (max-width: 400px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const PhotoLibrarySearchContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "end",
  width: "100%",
  '@media (max-width: 754px)': {
    gap: "12px"
  },
  '@media (max-width: 600px)': {
    flexDirection: "row",
    alignItems: "end",
    justifyContent: "space-between",
  },
  '@media (max-width: 500px)': {
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "start",
    gap: "12px"
  },
})

const SearchTextField = styled(TextField)({
  width: "calc(100% - 220px)",
    "& .Mui-focused": {
      "& fieldset": {
          border: "1px solid #DDDDDD !important",
          "&:hover":{
              border: "1px solid #DDDDDD !important"
          } }
      },
    "& fieldset": {
      border: "1px solid #DDDDDD !important"
   },
    "&:hover":{
      border : "none"
    },
  '& .MuiOutlinedInput-root': {
    fontFamily: "Open Sans",
    height: "48px",
    background: "white",
    '& fieldset': {
      border: "1px solid #DDDDDD",
      borderRadius: "8px",
    },
    '&.Mui-focused fieldset': {
      border: "1px solid #000000",
    },
    '&:hover fieldset': {
      borderColor: '#797979',
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
    },
  },
  '& .MuiInputBase-input': {
    height: '100%',
    width: "100%"
  },
  '@media (max-width: 854px)': {
    width: "calc(100% - 170px)"
  },
  '@media (max-width: 500px)': {
    width: "100%"
  },
});

const RenameTextField = styled(TextField)({
  width: "100%",
  fontFamily: "Open Sans",
 "& .Mui-focused":{
     "& fieldset":{
        border:"none",
        "&:hover":{
         border:"none"
        }
      }
    },
   '& .MuiOutlinedInput-root': {
    color: "#4D4D4D",
    fontFamily: "Open Sans",
    "& fieldset":{
      border:"1px solid #DDDDDD !important",
      borderRadius: "8px",
      padding: "4px",
    },
     "&:hover":{
      border:"none"
    },
   }
 })

const StyledSelect = styled(Box)({
  "& .library_sort_select":{
    height: "48px",
    width: '202px',
    borderRadius: "8px",
    "& .Mui-focused": {
      "& fieldset": {
          border: "1px solid #DDDDDD !important",
          "&:hover":{
              border: "1px solid #DDDDDD !important"
          }
      }
    },
    "& fieldset": {
      border: "1px solid #DDDDDD !important"
  },
   "&:hover":{
      border : "none"
   },
    '& .place_library_text':{
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 400,
      color: '#B3B3B3',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000000',
      borderSize: "1px",
    },
    '&.Mui-focused:hover fieldset': {
      borderColor: '#000000'
    },
    '@media (max-width: 854px)': {
      width: 'clamp(150px, 10vw, 202px)'
    },
    '@media (max-width: 754px)': {
      width: '220px'
    },
    '@media (max-width: 600px)': {
      width: "150px"
    },
    '@media (max-width: 500px)': {
      width: "94vw"
    },
    '@media (max-width: 450px)': {
      width: "92vw"
    },
    '@media (max-width: 400px)': {
      width: "91vw"
    },
    "& .sort_Item":{
      fontSize: "14px",
      fontWeight: 400,
      fontFamily: "Open Sans",
      color: "#4D4D4D"
    }
  }
})

const PhotosLibraryFormControl = styled(FormControl)({
  display: 'flex',
  flexDirection: "column",
  gap: "4px",
  '& .MuiFormHelperText-root': {
    marginLeft:"0px",
    fontWight:600
  },
  "& .sort_heading" : {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Open Sans",
    color: "#4D4D4D"
  }
});

const CardBox = styled(Box)({
  padding: "10px 0px",
  "& .headBox": {
    display: "flex",
    padding: "10px 0px",
    width: "100%",
    gap: "10px",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "22px",
      fontFamily: "Open Sans"
    }
  },
  "& .cardsBox": {
    width: "100%",
    gap: "25px 15px",
    display: "flex",
    flexWrap: "wrap"
  },
  "& .imageCard": {
    background: "#FFFDD8",
    width: "168px",
    height: "168px",
    borderRadius: "8px",
    boxSizing: "border-box",
    "&:hover": {
      border: "1px solid #D9D9D9",
      background: "#FCF670"
    },
    '@media (max-width: 580px)': {
      width: "160px",
    },
  },
  "& .imageBox": {
    height: "125px",
    width: "100%",
    borderRadius: "8px",
    position: "relative",
    "& .library_img":{
      width: "100%",
      height: "100%",
      borderRadius: "8px",
    }
  },
  "& .videoBox": {
    height: "125px",
    width: "100%",
    borderRadius: "8px",
    position: "relative",
    "& .library_video":{
      width: "100%",
      height: "100%",
      objectFit: "fill",
      borderRadius: "8px",
    }
  },
  "& .titleBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    padding: "10px",
    boxSizing: "border-box",
    width: "100%",
    height: "43px",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      fontFamily: "Open Sans",
      fontWeight: 400,
      lineHeight: "21px",
      fontSize: "14px",
    },
    "& .file_name": {
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    }
  },
  "& .moreButton": {
    cursor: "pointer"
  },
  "& .moreButtonActive": {
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: "50%"
  },
  "& .moreMenu": {
    position: "absolute",
    zIndex: 1,
    top: 34,
    right: -85,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& .MuiButton-root": {
      backgroundColor: "#fff",
      fontFamily: "Open Sans",
      textTransform: "none",
      color: "#4D4D4D",
      lineHeight: "21px",
      fontWeight: 400,
      gap: "10px",
      fontSize: "14px",
      width: "116px",
      height: "36px",
      boxSizing: "border-box",
      justifyContent: "flex-start",
      padding: "0px 16px",
      "& img": {
        width: "18px",
        height: "18px"
      }
    }
  },
  "& .deleteBtn": {
    borderTopRightRadius: "8px",
    border: "1px solid #DDDDDD",
    borderTopLeftRadius: "8px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  "& .renameBtn": {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    border: "1px solid #DDDDDD",
    borderTop: "none"
  },
  "& .playBtn": {
    position: "absolute",
    top: "40%",
    left: "45%",
    cursor: "pointer"
  }
});

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    backgroundColor: "#4D4D4D",  
    width: "1100px",
    height: "730px",
    boxSizing: "border-box",
    borderRadius: "20px",        
    padding: "40px",
    "& .image_content": {
      objectFit: "contain",
      width: "100%",
      height: "100%" 
    },
    "& .video_content": {
      objectFit: "contain",
      width: "100%",
      height: "100%" 
    },
    '@media (max-width: 650px)': {
      height: "500px",
      padding: "30px",
    },
    '@media (max-width: 500px)': {
      height: "400px",
      padding: "25px",
    },
    '@media (max-width: 400px)': {
      height: "300px",
      padding: "20px",
    },
  },
});

const DialogContentStyled = styled(DialogContent)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "0",
  "& .closeIconBtn": {
      position: "absolute",
      color: "#FFFFFF",
      top: "10px",
      right: "10px"
  }
});

const ModalContainer = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margin: "20px 0px",
  "& .paper": {
    maxHeight:'fit-content',
    width:"fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    "& .MuiTypography-body1": {
      color: "#4D4D4D",
      lineHeight: "27px",
      fontWeight: 600,
      fontSize: "20px"
    },
    "& .MuiTypography-body2": {
        color: "#4D4D4D",
        lineHeight: "21px",
        fontWeight: 400,
        fontSize: "16px",
        maxWidth: "500px",
        textAlign: "center"
      },
      '@media (max-width: 375px)': {
        padding: "20px",
      },
  },
  "& .paper_success":{
    padding: "40px !important",
  },
  "& .paper_upload": {
    maxHeight:'fit-content',
    width:"600px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
    '@media (max-width: 775px)': {
      width: "550px"
    },
    '@media (max-width: 675px)': {
      width: "500px"
    },
    '@media (max-width: 575px)': {
      width: "450px"
    },
    '@media (max-width: 475px)': {
      width: "350px"
    },
    '@media (max-width: 375px)': {
      width: "280px",
      padding: "20px",
    },
  },
  "& .renamepaper": {
    "& .MuiOutlinedInput-input": {
        padding: "4px !important",
        borderRadius: "8px",
        border: "1px solid #DDDDDD",
        width: "100%"
    }
},
  "& .closeIconBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .buttonBox": {
    display: "flex",
    gap: "20px",
    padding: "20px 0px",
    "& .MuiButton-root": {
        textTransform : "none",
        borderRadius: "8px",
        padding: "10px 16px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "212px",
      height: "48px",
      '@media (max-width: 675px)':{
        width:"200px !important"
        },
      '@media (max-width: 575px)':{
        width:"180px !important"
        },
      '@media (max-width: 475px)':{
         width:"130px !important"
        },
      '@media (max-width: 375px)':{
         width:"100px !important",
         padding:"10px 5px"
       },
     },
    "& .cancleBtn": {
        color: "#000000",
        border: "1px solid #000000"
    },
    "& .deleteBtn": {
        color: "white",
        backgroundColor: "#000000"
    }
  }
});

const BoldHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign:"center",  
  fontSize: '20px',
  color: '#4D4D4D',
})

const MediumHeadTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontSize: '16px',
  color: '#4D4D4D',
  textAlign:"center",
})

const BoldTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 600,
  textAlign:"center",  
  fontSize: '16px',
  color: '#4D4D4D',
})

const MediumTypo = styled(Typography)({
  fontFamily: 'Open Sans',
  fontWeight: 400,
  fontSize: '14px',
  color: '#4D4D4D',
  textAlign:"center",
})

const UploadHeadBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: '100%',
  flexDirection: "column",
  alignItems: "center",
  "& .succes_image":{
     width: "80px",
     height: "80px",
     marginTop: "35px"
  }
})

const UploadContentBox = styled(Box)({
  border: '2px dashed #B3B3B3',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: '8px',
  padding: 'clamp(19px, 3.3vw, 32px)',
  gap: 'clamp(19px, 3.3vw, 32px)'
})

const UploadContent = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "6px"
})

const UploadBrowseBtn = styled(Box)({
  border: '1px solid #B3B3B3',
  color: '#000000',
  fontWeight: 600,
  fontSize: '16px',
  padding: '16px 32px',
  borderRadius: '8px',
  fontFamily: 'Open Sans',
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
})

const SelectBox = styled(Box)({
  color: '#4D4D4D',
  fontWeight: 400,
  fontSize: '16px',
  fontFamily: 'Open Sans',
  width: "100%",
  textAlign:"center",
  "& .select_file": {
    margin: "0px 5px",
    color: '#4D4D4D',
    fontWeight: 400,
    fontSize: '12px',
    fontFamily: 'Open Sans',
  }
})

const UploadButtonBox = styled(Box)({
     display: "flex",
     gap: "20px",
    "& .savedBtn": {
      color: "white",
      backgroundColor: "#000000"
    },
    "& .MuiButton-root": {
      width: "212px",
      height: "48px",
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      justifyContent: "center",
      alignItems: "center",
      '@media (max-width: 675px)': {
        width: "200px !important"
      },
      '@media (max-width: 575px)': {
        width: "180px !important"
      },
      '@media (max-width: 475px)': {
        width: "130px !important"
      },
      '@media (max-width: 375px)': {
        width: "100px !important",
        padding: "10px 5px"
      },
    },
    "& .cancleBtn": {
        color: "#000000",
        border: "1px solid #000000"
    },
})

const RecordNotFoundBox = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .record_content": {
   color: '#B3B3B3',
  fontWeight: 600,
  fontSize: '20px',
  fontFamily: 'Open Sans',
  padding: "20px 0px"
  }
})

const ValidBox = styled(Box)({
  color: '#D62222',
  fontWeight: 400,
  fontSize: '12px',
  fontFamily: 'Open Sans',
})
// Customizable Area End 