// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfmarkyaiapiintegration from "../../blocks/cfmarkyaiapiintegration/src/Cfmarkyaiapiintegration";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Brainstromwithjarvis from "../../blocks/landingpage/src/BrainStromWithJarvis.web";
import Breadcrumbnavigation from "../../blocks/breadcrumbnavigation/src/BreadcrumbNavigationPage.web";
import CommunityForum from "../../blocks/communityforum/src/CommunityForum";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs.web";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Imagemanagement2 from "../../blocks/imagemanagement2/src/Imagemanagement2";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfwebotopenaiapi from "../../blocks/cfwebotopenaiapi/src/Cfwebotopenaiapi";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Cflookerstudioapi from "../../blocks/cflookerstudioapi/src/Cflookerstudioapi";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions.web";
import CustomisableUserProfileEdit from "../../blocks/customisableuserprofiles/src/CustomisableUserProfileEdit.web"
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Share from "../../blocks/share/src/Share";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Cfhypeauditorapiintegration from "../../blocks/cfhypeauditorapiintegration/src/Cfhypeauditorapiintegration";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ContentManagementCampaigns from "../../blocks/contentmanagement/src/ContentManagementCampaigns.web";
import Dashbored from "../../blocks/dashboard/src/DashboardCommunityManager.web"
import NewCommunityManager from "../../blocks/dashboard/src/NewCommunityManager.web"
import Chatgpt from "../../blocks/chatgpt/src/Chatgpt.web";
import CustomForm from "../../blocks/customform/src/CustomForm.web"
import GuidedWalkthrough from "../../blocks/customform/src/GuidedWalkthrough.web"
import CampaignAddContent from "../../blocks/chatgpt/src/CampaignAddContent.web";
import GenerateContent from "../../blocks/chatgpt/src/GenerateContent.web";
import ConnectedAccounts from "../../blocks/settings2/src/ConnectedAccounts.web";
import PhotosLibrary from "../../blocks/photolibrary/src/PhotosLibrary.web";
import CampaignResults from "../../blocks/contentmanagement/src/CampaignsResults.web";
import ContentManagementEdit from "../../blocks/contentmanagement/src/ContentManagementEdit.web";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import MyWorkspaces from "../../blocks/customisableuserprofiles/src/MyWorkspaces.web";
import NewWorkspace from "../../blocks/customisableuserprofiles/src/NewWorkspace.web";
import WorkspaceName from "../../blocks/customisableuserprofiles/src/WorkspaceName.web";
import CampaignWorkspace from "../../blocks/customisableuserprofiles/src/CampaignWorkspace.web";
import Services from "../../blocks/landingpage/src/Services.web"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const routeMap = {
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfmarkyaiapiintegration:{
 component:Cfmarkyaiapiintegration,
path:"/seorecommendations",
sideBar: true
},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Brainstromwithjarvis:{
component:Brainstromwithjarvis,
 path:"/Brainstromwithjarvis"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
Interactivefaqs:{
 component:Interactivefaqs,
path:"/Interactivefaqs",
sideBar: true
},
AddInteractivefaqs:{
 component:AddInteractivefaqs,
path:"/AddInteractivefaqs"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Imagemanagement2:{
 component:Imagemanagement2,
path:"/Imagemanagement2"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfwebotopenaiapi:{
 component:Cfwebotopenaiapi,
path:"/Cfwebotopenaiapi"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Cflookerstudioapi:{
 component:Cflookerstudioapi,
path:"/Cflookerstudioapi"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},

CustomisableUserProfileEdit:{
  component:CustomisableUserProfileEdit,
  path:"/CustomisableUserProfileEdit",
  sideBar: true
},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
PrivacyPolicy:{
  component:PrivacyPolicy,
 path:"/PrivacyAndPolicy"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
Dashbored:{
  component:Dashbored,
 path:"/dashbored",
 sideBar: true
},
 NewCommunityManager:{
  component:NewCommunityManager,
 path:"/NewCommunityManager",
 sideBar: true
},
EditCommunityManager:{
  component: NewCommunityManager,
  path: `/EditCommunityManager/:id`,
  exact: true,
  sideBar: true
},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide",
sideBar: true
},
Share:{
 component:Share,
path:"/Share"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Cfhypeauditorapiintegration:{
 component:Cfhypeauditorapiintegration,
path:"/Cfhypeauditorapiintegration"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Breadcrumbnavigation:{
  component:Breadcrumbnavigation,
   path:"/Breadcrumbnavigation",
   header:true,
  },
Campaigns:{ 
 component:ContentManagementCampaigns,
path:"/Campaigns",
sideBar: true
},
Chatgpt:{
  component:Chatgpt,
path:"/Contents",
sideBar: true
},
GenerateContent:{
  component:GenerateContent,
path:"/GenerateContent",
sideBar: true
},
CustomForm:{
  component:CustomForm,
path:"/CustomForm"
},
GuidedWalkthrough:{
  component:GuidedWalkthrough,
path:"/GuidedWalkthrough",
sideBar: true
},
CampaignAddContent:{
  component:CampaignAddContent,
path:"/AddContent",
sideBar: true
},
ConnectedAccounts:{
  component:ConnectedAccounts,
path:"/ConnectedAccounts",
sideBar: true
},
PhotosLibrary:{
  component:PhotosLibrary,
path:"/PhotosLibrary",
sideBar: true
},
CampaignsResults:{
  component: CampaignResults,
  path: "/CampaignsResults",
  sideBar: true
},
EditContent:{
  component: ContentManagementEdit,
  path: "/EditContent",
  sideBar: true
},
MyWorkspaces:{
  component: MyWorkspaces,
  path: "/MyWorkspaces",
  sideBar: true
},
NewWorkspace:{
  component: NewWorkspace,
  path: "/NewWorkspace",
  sideBar: true
},
WorkspaceName:{
  component: WorkspaceName,
  path: "/WorkspaceName",
  sideBar: true
},
CampaignWorkspace:{
  component: CampaignWorkspace,
  path: "/CampaignWorkspace",
  sideBar: true
},
  Home: {
    component: LandingPage,
    path: '/',
    header:true,
    exact: true,
    videoBackGround:true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
  Services:{
    component:Services,
    path:"/services",
    header:true,
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View>
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
        <ToastContainer/>
      </View>
    </BuilderProvider>
  );
}

export default App;