// Customizable Area Start
import React from "react";
import {
  Box,
  Backdrop,
} from "@mui/material";
import EmailAccountRegistration from "../../email-account-registration/src/EmailAccountRegistration.web"
import EmailAccountLoginBlock from "../../email-account-login/src/EmailAccountLoginBlock.web"
import ForgetPassword from "../../forgot-password/src/ForgetPassword.web";
import VideoBackgrounds from "../../videobackgrounds/src/VideoBackgrounds.web"

import LandingPageHeaderController, {
  Props,
} from "./LandingPageHeaderController";
import HeaderTopNavigation from "../../../components/src/HeaderTopNavigation"
export default class LandingPageHeader extends LandingPageHeaderController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.isBackgroundVideo ?
          <VideoBackgrounds id="landingPageBackGround" navigation={this.props.navigation}>
            <Box sx={{ flexShrink: 0 }}>
              <HeaderTopNavigation isActive={this.state.isBackgroundVideoAvailable}
                handlelogin={this.handleOpenLogInForm.bind(this)}
                handlesignup={this.handleOpenSignUpForm.bind(this)}
                handleGoogleLogin={this.handleGoogleRegister.bind(this)}
                firstName={this.state.userData.firstName}
                profileImage={this.state.userData.profileImage}
                data-testid="headerComponent"
                navigateTo={this.handleNavigate.bind(this)}
              />
            </Box>
            {this.props.children}
          </VideoBackgrounds>
          :
          <Box sx={{ width: '100vw', height: '100vh',position:"relative",}}>
            <Box sx={{ flexShrink: 0 , position:"fixed",width:"100%", height:"fit-content"}}>
              <HeaderTopNavigation isActive={false}
                handlelogin={this.handleOpenLogInForm.bind(this)}
                handlesignup={this.handleOpenSignUpForm.bind(this)}
                handleGoogleLogin={this.handleGoogleRegister.bind(this)}
                firstName={this.state.userData.firstName}
                data-testid="headerComponent"
                navigateTo={this.handleNavigate.bind(this)}
              />
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto',marginTop:"82px", }}>
              {this.props.children}
            </Box>
          </Box>
        }
        <Backdrop className="backdrop_modal" open={this.state.isOpenLogInForm} onClick={this.handleCloseLogInForm.bind(this)} data-testid='loginUpBackdrop' sx={{ zIndex: 1 }}>
          {this.state.isOpenLogInForm && <EmailAccountLoginBlock
            id="logInForm"
            onClose={this.handleCloseLogInForm.bind(this)}
            navigation={this.props.navigation}
            data-testid='logInForm'
            goToRegistration={this.handleOpenRegistrationFromLogIn.bind(this)}
            goToForgetPassword={this.handleOpenForgetPassword.bind(this)}
          />}
        </Backdrop>

        <Backdrop className="backdrop_modal" open={this.state.isOpenSignUpForm} onClick={this.handleCloseSignUpForm.bind(this)} data-testid='signUpBackdrop'>
          {this.state.isOpenSignUpForm && <EmailAccountRegistration
            id="signUpForm"
            navigation={this.props.navigation}
            data-testid='signUpForm'
          />}
        </Backdrop>

        <ForgetPassword
          open={this.state.isOpenForgetPasswordForm}
          navigation={this.props.navigation}
          data-testid='forgetPassword'
          onClose={this.handleCloseForgetPasswordForm.bind(this)}
          goToLoginForm={this.handleOpenLogInForm.bind(this)}
        />
      </>
    );
  }
}

// Customizable Area End