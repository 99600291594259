// Customizable Area Start
import React from "react";
import {
  TextField,
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
  Backdrop,
  Modal,
  Paper
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { signatureview, VolumeIcon, ReloadIcon, DislikeIcon, sendicon, Loader } from "./assets";
import CloseIcon from '@mui/icons-material/Close';
import styled from '@emotion/styled';
const themes = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageController, {
  Props, configJSON
} from "./LandingPageController";

import Footerbar from "../../../components/src/Footerbar"

export default class BrainsStromWithJarvis extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  renderLoginSignupModal = () =>{
    return(
      <ModalContainer
      open={this.state.openLoginSignup}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      className="deleteModal"
    >
      <Paper className="JarvaysPaper">
        <IconButton
          onClick={this.closeLoginSignup}
          data-testId="closeJarvysTestID"
          className="closeJarvysBtn"
        >
          <CloseIcon />
        </IconButton>
        <Typography className="jarvaysHeadMsgBox" data-testId="closelabelTestID"> 
            {configJSON.jarvysHeadMsg}
        </Typography>
        <Typography className="jarvys_platform_msg">{configJSON.platformMessgae}</Typography>
        <Box className="buttonJarvysBox">
          <Button className="signupBtn" onClick={this.handleOpenSignUpForm.bind(this)}>
            {configJSON.signupText}
          </Button>
          <Button className="loginBtn" onClick={this.handleOpenLogInForm.bind(this)}>{configJSON.loginText}</Button>
        </Box>
      </Paper>
    </ModalContainer>
    )
  }

  renderProcessModal = () => {
    return (
       <ModalContainer
         className="deleteModal"
         open={this.state.jarvysmodelopenlanding}
         disableAutoFocus
         disablePortal
         disableEnforceFocus
        >
           <Paper className="JarvaysPaper" >
             <IconButton
              onClick={this.handleclosejarvysmodel.bind(this)}
              className ="closeJarvysBtn"
           >
              <CloseIcon />
           </IconButton>
            <StyledModelTextGradient>{'JARVYS AI'}</StyledModelTextGradient>
            <ProcessLoaderImgBox>
              <img src={Loader} alt="Loading..." className="loader_Img"/>
            </ProcessLoaderImgBox>
            <ModelBottomTypo>{'JARVYS processes your question...'}</ModelBottomTypo>
        </Paper>
       </ModalContainer>
     );
   };

  render() {
    return (
      <ThemeProvider theme={themes}>

        <Box>
          <StyleBrainStormContainer>
            <BrainStormBox>
            <Box>
            <Grid item xs={12} style={{ backgroundColor: '#D7DADA' }}>
              <JarvysAIBox >
                  <StyledJarvisTypo >JARVYS AI</StyledJarvisTypo>
              </JarvysAIBox>
            </Grid>
            <JarvysGridBox item xs={12} >

              {
                !this.state.values.length ? (

                  <Box display={'flex'} justifyContent={'center'} alignItems={"center"}>
                    <Box padding={'20px'} >
                      <Box >

                        <Box>
                          <StyledLetsStartTypo >Lets start to talk with JARVYS</StyledLetsStartTypo>
                        </Box>
                        <Box display={'flex'} justifyContent={"space-around"} >
                          <Box display={"flex"} justifyContent={"center"} alignItems={'center'} flexDirection={"column"}>
                            <StyledLetsStartChildTypo >You can ask about anything that will help you </StyledLetsStartChildTypo>
                            <StyledLetsStartChildTypo >improve your business, or find out where to start... </StyledLetsStartChildTypo>
                          </Box>
                          <StyledTextLogo src={signatureview} alt="signatureimg" />
                        </Box>
                      </Box>

                    </Box>

                  </Box>
                ) : (
                  <MainContainerBox>
                      {
                        this.state.values.map((item, index) => {
                         return (    
                          <JarvysChatBox key={index} >
                          <JarvysChatMainBox>
                            <QuestionTextBox>{"Your question"}</QuestionTextBox>
                            <UserChatBox >
                              <JarvysChatQuestion>
                                 {item.question}
                              </JarvysChatQuestion>
                            </UserChatBox>
                          </JarvysChatMainBox>
                          <Box>
                            <JarvysChatText >{"JARVYS’s answer"}</JarvysChatText>
                            <JarvysAnsText 
                                dangerouslySetInnerHTML={{
                                  __html: item.jarvysAnswer,
                                }}
                            >
                            </JarvysAnsText>
                             <JarvysAnsIconBox >
                              <img className="JarvysIcon" src={VolumeIcon} alt="" />
                              <img className="JarvysIcon" src={ReloadIcon} alt="" />
                              <img className="JarvysIcon" src={DislikeIcon} alt="" />
                            </JarvysAnsIconBox>
                          </Box>
                        </JarvysChatBox>

                        )})
                      }
                  </MainContainerBox>
              )

              }
            </JarvysGridBox>


            <BrainInputBox>
              <StyledInputField
                fullWidth
                multiline
                maxRows={1}
                id="outlined-adornment-password"
                type={'text'}
                value={this.state.userMessage}
                onChange={this.handleUserMessageChange.bind(this)}
                placeholder='Enter your text'
                variant='outlined'
                InputProps={{
                  endAdornment: <IconButton
                    aria-label="toggle password visibility"
                    data-test-id="goToHomeButton"
                    onClick={this.askToJarvys.bind(this)}
                    disabled = {this.state.userMessage === "" ? true : false} 
                    edge="end"
                  >
                    <img src={sendicon} alt="" />
                  </IconButton>
                }}
              />
              <Box  marginTop={'1vh'}>
              <Typography style={{textAlign:'start',marginLeft:'15px',color:'#909090',fontFamily:'Open Sans',fontWeight:400,fontSize:'12px'}}>JARVYS AI can make mistakes. Consider checking important information.</Typography>

              </Box>
            </BrainInputBox>
           </Box>

            <Footerbar isActive={this.state.isBackgroundVideoAvailable} />
          </BrainStormBox>
            {this.renderLoginSignupModal()}
            {this.renderProcessModal()}
          </StyleBrainStormContainer>
        </Box>
      </ThemeProvider>
    )
  }
}

const JarvysAIBox = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  width: '100%',
  backgroundColor: '#D7DADA',
  marginTop: "10px"
})

const JarvysGridBox = styled(Grid)({
  backgroundColor: '#D7DADA', 
  paddingBottom: "10px",
  height: "calc(100vh - 360px)", 
  overflow: "scroll",
  scrollbarWidth: "none",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "@media (max-width: 800px)": {
    height: "calc(100vh - 474px)",
  },
})

const JarvysChatBox = styled(Box)({
   padding: '0 5vw',
})

const JarvysChatMainBox = styled(Box)({
    marginTop: '24px'
})

const QuestionTextBox = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#4D4D4D',
    textAlign: 'right' as 'right'
})

const UserChatBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
    marginTop: '8px',
})

const JarvysChatQuestion = styled(Typography)({
    maxWidth: "67.42%",
    boxSizing: 'border-box' as 'border-box',
    background: '#EFEFEF',
    padding: "8px 16px 8px 16px",
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
    borderRadius: '16px 0px 16px 16px',
    "@media (max-width: 700px)": {
      maxWidth: "80%",
    },
    "@media (max-width: 600px)": {
      maxWidth: "90%",
    },
})

const JarvysChatText = styled(Typography)({
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '24px',
    color: '#4D4D4D',
})

const JarvysAnsText = styled(Typography)({
    maxWidth: "67.42%",
    marginTop: '8px',
    boxSizing: 'border-box' as 'border-box',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#4D4D4D',
    "@media (max-width: 700px)": {
      maxWidth: "80%",
    },
    "@media (max-width: 600px)": {
      maxWidth: "90%",
    },
})

const JarvysAnsIconBox = styled(Box)({
    marginTop: '14px',
    width: 'fit-content',
    display: 'none',
    gridTemplateColumns: 'auto auto auto',
    columnGap: '14px',
    "& .JarvysIcon": {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
    }
})

const MainContainerBox = styled(Box)({
  width: "100%",
  background: "#D7DADA",
})

const BrainInputBox = styled(Box)({
  padding: '5px 5vw',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'start',
  flexDirection: 'column',
  background: "#D7DADA",
})

const StyleBrainStormContainer = styled(Box)({

  height:'fit-content',
backgroundColor:'#D7DADA',
"& .backdrop_modal": {
  zIndex: 1500
},

});

const BrainStormBox = styled(Box)({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent:"space-between"
})



const StyledJarvisTypo = styled(Typography)({
  fontWeight: 600,
  background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Open Sans',
  WebkitBackgroundClip: 'text',
  textAlign: 'center',
  fontSize: '2.817vw',
  marginLeft:'5vw',
  '@media (max-width: 1238px)': {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    WebkitTextFillColor: 'transparent', 
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    fontSize: '2.817vw',
    textAlign: 'center',
    marginLeft:'5vw',


  },
  '@media (max-width: 1034px)': {
    fontWeight: 600,
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    fontFamily: 'Open Sans',
    fontSize: '2.817vw',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  marginLeft:'5vw',
  textAlign: 'center',
  },
  '@media (max-width: 918px)': {
    fontWeight: 600,
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    fontFamily: 'Open Sans',
    WebkitTextFillColor: 'transparent', marginTop: '10px', marginBottom: '10px',
    WebkitBackgroundClip: 'text',
    fontSize: '2.817vw',
  marginLeft:'5vw',
  textAlign: 'center',
  },
  '@media (max-width: 858px)': {
    fontWeight: 600,
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    fontFamily: 'Open Sans',
    fontSize: '2.817vw',
    WebkitTextFillColor: 'transparent',
  marginLeft:'5vw',
  textAlign: 'center',
    WebkitBackgroundClip: 'text',

  }, '@media (max-width: 768px)': {
    fontWeight: 600,
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    fontFamily: 'Open Sans',
    WebkitTextFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    fontSize: '4.662vw',
    textAlign: 'center',

  }, '@media (max-width: 562px)': {
    fontWeight: 600,
  marginLeft:'5vw',
  fontFamily: 'Open Sans',
    WebkitTextFillColor: 'transparent', 
    fontSize: '4.662vw',
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    textAlign: 'center',
  },
  '@media (max-width: 626px)': {
    fontWeight: 600,
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
  marginLeft:'5vw',
  fontFamily: 'Open Sans',
    WebkitTextFillColor: 'transparent', 
    WebkitBackgroundClip: 'text',
    fontSize: '4.662vw',
    textAlign: 'center',

  }, '@media (max-width: 468px)': {
    fontWeight: 600,
    fontFamily: 'Open Sans',
    WebkitTextFillColor: 'transparent', 
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    fontSize: '4.662vw',
    textAlign: 'center',

  }, '@media (max-width: 386px)': {
    fontWeight: 600,
  marginLeft:'5vw',
  fontFamily: 'Open Sans',
    background: 'linear-gradient(to right,#0066FF, #E8CC17)',
    WebkitTextFillColor: 'transparent', 
    WebkitBackgroundClip: 'text',
    fontSize: '4.662vw',
    textAlign: 'center',
  },

})


const StyledLetsStartTypo = styled(Typography)({
  fontWeight: 400, fontSize: '42px', fontFamily: 'Open Sans', color: '#000000', marginBottom: '30px', padding: '5px',
  '@media (max-width: 1238px)': {
    fontWeight: 400, fontSize: '42px', fontFamily: 'Open Sans', color: '#000000', marginBottom: '30px', padding: '5px',


  },
  '@media (max-width: 1034px)': {
    fontWeight: 400,
    padding: '5px',
    fontSize: '40px', fontFamily: 'Open Sans',
    color: '#000000', marginBottom: '30px',
  },
  '@media (max-width: 918px)': {
    fontWeight: 400, fontSize: '35px', fontFamily: 'Open Sans',
    padding: '5px',
    color: '#000000', marginBottom: '30px',
  },
  '@media (max-width: 858px)': {
    fontWeight: 400, fontSize: '22px',
    marginBottom: '30px', padding: '5px',
    fontFamily: 'Open Sans', color: '#000000',

  }, '@media (max-width: 768px)': {
    fontWeight: 400, fontSize: '20px', fontFamily: 'Open Sans',
    marginBottom: '30px', padding: '5px',
    color: '#000000',

  }, '@media (max-width: 562px)': {
    fontWeight: 400, fontSize: '15px', fontFamily: 'Open Sans',
    color: '#000000', marginBottom: '30px',
    padding: '5px',
  },
  '@media (max-width: 626px)': {
    fontWeight: 400, fontSize: '15px', fontFamily: 'Open Sans',
    padding: '5px',
    color: '#000000', marginBottom: '30px',

  }, '@media (max-width: 468px)': {
    fontWeight: 400, fontSize: '13px', fontFamily: 'Open Sans',
    color: '#000000', marginBottom: '30px',
    padding: '5px',

  }, '@media (max-width: 386px)': {
    fontWeight: 400, fontSize: '12px',
    marginBottom: '30px', padding: '5px',
    fontFamily: 'Open Sans', color: '#000000',
  },

})

const StyledLetsStartChildTypo = styled(Typography)({
  fontWeight: 400, fontSize: '20px', color: '#000000', fontFamily: 'Open Sans', marginBottom: '5px',
  '@media (max-width: 1238px)': {
    fontWeight: 400, fontSize: '20px', color: '#000000', fontFamily: 'Open Sans', marginBottom: '5px',


  },
  '@media (max-width: 1034px)': {
    fontWeight: 400, fontSize: '15px', color: '#000000',
    fontFamily: 'Open Sans', marginBottom: '5px',
  },
  '@media (max-width: 918px)': {
    fontWeight: 400,
    marginBottom: '5px',
    fontSize: '14px', color: '#000000', fontFamily: 'Open Sans',
  },
  '@media (max-width: 858px)': {
    fontWeight: 400, fontSize: '13px', color: '#000000',
    fontFamily: 'Open Sans',
    marginBottom: '5px',

  }, '@media (max-width: 768px)': {
    fontWeight: 400,
    color: '#000000', fontFamily: 'Open Sans', marginBottom: '5px',
    fontSize: '12px',

  }, '@media (max-width: 562px)': {
    fontWeight: 400, fontSize: '11px',
    marginBottom: '5px',
    color: '#000000', fontFamily: 'Open Sans',
  },
  '@media (max-width: 626px)': {
    fontWeight: 400, fontSize: '10px',
    fontFamily: 'Open Sans', marginBottom: '5px',
    color: '#000000',

  }, '@media (max-width: 468px)': {
    fontWeight: 400, fontSize: '9px', color: '#000000',
    fontFamily: 'Open Sans', marginBottom: '5px',

  }, '@media (max-width: 386px)': {
    fontWeight: 400, fontSize: '8px', color: '#000000',
    fontFamily: 'Open Sans', marginBottom: '5px',
  },

})

const StyledTextLogo = styled.img({

  cursor: 'pointer',
  width: '50px',

  '@media (max-width: 1018px)': {
    width: '70px',
    cursor: 'pointer',


  }, '@media (max-width: 1034px)': {
    width: '50px',
    cursor: 'pointer',


  },
  '@media (max-width: 974px)': {
    cursor: 'pointer',

    width: '40px',


  }, '@media (max-width: 918px)': {
    cursor: 'pointer',

    width: '40px',


  },
  '@media (max-width: 858px)': {
    cursor: 'pointer',
    width: '40px',


    height: '40px'

  },
  '@media (max-width: 768px)': {
    cursor: 'pointer',

    width: '40px',

  }, '@media (max-width: 626px)': {
    width: '40px',
    cursor: 'pointer',

  }, '@media (max-width: 468px)': {
    width: '30px',
    cursor: 'pointer',
  }, '@media (max-width: 386px)': {
    cursor: 'pointer',
    width: '20px',
  },


})

const StyledInputField = styled(TextField)({
   marginBottom: "0px",
   "& .MuiInputBase-root": {
     fontFamily: 'Open Sans',
     height: '66px',
     margin: 'auto',
     borderRadius: '100px',
     backgroundColor: "#FFFFFF",
     fontSize: '16',
   },
   "& .MuiOutlinedInput-root": {
     "&:hover fieldset": {
       borderColor: "#B3B3B3",
     },
     "&.Mui-focused fieldset": {
       borderColor: "#B3B3B3",
       borderWidth: "1px",
     },
   },
   "& .MuiOutlinedInput-input": {
     fontFamily: 'Open Sans',
     color: '#4D4D4D',
     paddingLeft: '20px',
     fontSize: "16px",
     fontWeight: 400,
     scrollbarWidth: "none",
     "&::-webkit-scrollbar": {
       display: "none",
     },
     "&::placeholder": {
       fontSize: "16",
       fontFamily: 'Open Sans',
       color: '#4D4D4D',
     },
     "&:not(:placeholder-shown)": {
       fontFamily: 'Open Sans',
       color: '#4D4D4D',
     },
   },
   "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderRadius: "100px",
    borderColor: "#B3B3B3",
  },
  "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
    borderWidth: "1px",
    borderColor: "#B3B3B3",
  },
   "& .MuiFormHelperText-root.Mui-error": {
     fontSize: "12px",
     fontWeight: 400,
     lineHeight: "18px",
     fontFamily: "Lato",
     color: "#DC2626",
   },
   "& .MuiInputBase-input[type='date']": {
     color: 'red',
     textTransform: 'uppercase',
     lineHeight: "19.2px",
   },
   "& .MuiFormHelperText-contained": {
     marginRight: "0px",
     marginLeft: "0px",
   },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
     borderColor: "#B3B3B3"
   },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
     border: "1px solid #FFFFFF",
    }
});

const StyledquestionTypo = styled(Typography)({
  fontFamily: 'Open Sans', fontWeight: 600, fontSize: '16px', color: '#4D4D4D', marginBottom: '10px', 
})

const StyleduserquestionTypo = styled(Typography)({
  lineHeight: '22.4px', fontFamily: 'Open Sans', fontWeight: 400, fontSize: '14px', color: '#4D4D4D'
})

const StyledjarvysanswerTypo = styled(Typography)({
  fontFamily: 'Open Sans', fontWeight: 600, fontSize: '16px', color: '#4D4D4D', marginBottom: '10px', lineHeight: '21.79px' 
})

const StyledModelContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "#FFFFFF",
  maxHeight: '85vh',
  width: "40vw",
  padding: "40px",
  borderRadius: '12px',
});



const StyledModelInnerContainer = styled(Box)({
  width: '100%',
  height: "100%",
  '@media (max-width: 858px)': {
      flexDirection: 'column-reverse',
      justifyContent: "start",
      gap: "0px",
  },
});

const StyledModelTextGradient=styled(Typography)({
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: '64px',
    background: 'linear-gradient(to right, #0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
})

const ModelBottomTypo=styled(Typography)({
  fontWeight: 600,
  fontFamily: 'Open Sans',
  fontSize: '18px',
  color:'#515151',
})

const ModalContainer = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  "& .JarvaysPaper": {
    maxHeight:'fit-content',
    width:"520px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    borderRadius: "24px",
    gap: "20px 0px",
    position: "relative",
    boxSizing: "border-box",
  },
  "& .closeJarvysBtn": {
    position: "absolute",
    color: "black",
    top: "10px",
    right: "10px"
  },
  "& .jarvaysHeadMsgBox":{
    background: 'linear-gradient(to right, #0066FF, #E8CC17)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign:"center",  
    fontSize: '48px !important',
    lineHeight: "65.37px !important",
  },
  "& .jarvys_platform_msg":{
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign:"center",  
    fontSize: '18px',
    color: '#4D4D4D',
    marginTop: "40px",
  },
  "& .buttonJarvysBox": {
    display: "flex",
    gap: "20px",
    marginTop: "30px",
    "& .MuiButton-root": {
        textTransform : "none",
        borderRadius: "8px",
      lineHeight: "24px",
      fontWeight: 600,
      fontSize: "16px",
      width: "212px",
      height: "48px"
    },
    "& .signupBtn": {
        color: "#000000",
        border: "1px solid #000000",
        cursor: "pointer"
    },
    "& .loginBtn": {
        color: "white",
        backgroundColor: "#000000",
        cursor: "pointer"
    }
  }
});

const ProcessLoaderImgBox = styled(Box)({
  width: '51px',
  height: '50px',
  animation: 'rotate 2s linear infinite',
  "& .loader_Img" : {
    width: '51px',
    height: '50px',
    animation: 'rotate 2s linear infinite',
  }
})

// Customizable Area End